@import 'concert-ui-library/dist/styles/theme/styles/colors';
@import 'concert-ui-library/dist/styles/theme/styles/layout';

[data-testid='header-calendar'] {
    .p-calendar-w-btn-right .p-inputtext {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .p-calendar-w-btn-right .p-datepicker-trigger {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }
}

.settings-container {
    .sub-header {
        text-align: left;
        .user-selector-container.app-container {
            text-align: left;
            padding: 0rem 1.25rem 1.25rem 1.25rem;
        }

        .p-panel-header {
            text-align: left;
            border: 0;
        }
        .p-panel-content {
            display: none;
        }        
    }
    .extended-availability-table {
        .edit-body-template {
            text-align: right;
            .button-container {
               padding: 0.5rem
            } 
        }
     }
    
    .app-container {
        .p-toolbar {
            background: $functional-white;
            border: 1px solid $functional-white;
            padding: 1rem 1.25rem;
            border-radius: 4px;
        }
        .p-tabview-selected .p-tabview-title {
            color: $primary-orange;
        }
    }
}

.sub-title {
    margin-left: 20px;
    margin-right: 20px;
    text-align: left;
}

.settings-screen {
    margin: 20px 20px;

    .p-card-body {
        padding: 0;

        .p-card-content {
            padding: 0;
        }
    }
}
.p-datepicker-trigger {
    color: $functional-black;
    background: $functional-white;
    border: 1px solid $primary-orange;
}
.p-datepicker-trigger:enabled:hover {
    color: $functional-black;
    background: rgba($primary-light-orange, 0.5);
    border: 1px solid rgba($primary-orange, 0.5);
}
.availability-week {
    display: grid;
    grid-template-rows: 50px auto 50px;
    grid-template-columns: repeat(7, 1fr);
    height: 100%;

    
    .header {
        font-weight: bold;
        vertical-align: middle;
        line-height: 50px;
    }

    .content {
        border: 1px solid $secondary-color-gray;
        grid-row-start: 2;
        min-height: 200px;
        font-weight: 600;
        font-size: 0.9rem;
        cursor: pointer;

        > div {
            margin-bottom: 10px;
        }

        &.disabled {
            cursor: unset;
        }

        &:hover:not(.disabled) {
            border: 1px solid $primary-orange;
        }
    }

    .footer {
        grid-row-start: 3;
        grid-column-start: 1;
        grid-column-end: 8;
    }
}

.general-availability-footer {
    text-align: start;
    margin-top: -30px;
    margin-left: 20px;
    padding-bottom: 30px;
}
