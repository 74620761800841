@import 'concert-ui-library/dist/styles/theme/styles/colors';
@import 'concert-ui-library/dist/styles/theme/styles/layout';

$margin-h-width: 20px;
.overlay-gray {
    display: none;
    position: absolute;
    z-index: 999;
    background-color: rgba($secondary-color-gray, 0.8);
    height: calc(100%);
    width: 100%;

    &.visible {
        display: block;
    }
}

.sub-header {
    .p-panel-title {
        width: 100%;
    }

    .filter-button {
        width: auto;
        padding: 10px;

        .filter-button-area {
            margin-right: 10px;
        }

        .other-names {
            text-overflow: ellipsis;
            overflow: hidden;
            display: inline-block;
            max-width: 100px;
            vertical-align: bottom;
            white-space: nowrap;
            font-weight: bold;
        }
    }

    .scheduling-sub-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .filter-loader {
        margin-top: -35%;
    }

    .p-panel-header > .p-panel-icons {
        display: none;
    }

    .button-footer {
        display: flex;
        width: 100%;
        text-align: right;
        justify-content: flex-end;

        button:not(:last-child) {
            margin-right: 10px;
        }
    }
    .filter {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;       

        > .filter-section:not(:first-child) {
            margin-left: 20px;
            border-left: 1px solid $secondary-color-gray;
        }

        .filter-radios {
            display: flex;
            flex-direction: row;  
            margin-top: 8px;         
            padding-bottom: 30px;            
            
            .field-radiobutton{                
                margin-right: 15px;
                .p-radiobutton  {
                    margin-right: 10px;                    
                }
            }
        }

        .filter-section {            
            flex-grow: 0.5;
            text-align: left;
            padding: 1.25rem;

            .p-tabview-nav {
                border: 0;
            }

            .p-tabview-nav li .p-tabview-nav-link {
                border: 0;
            }

            .p-tabview-nav li.p-highlight .p-tabview-nav-link {
                border: 0;
            }
            .p-tabview-selected .p-tabview-title {
                color: $primary-color;
            }
        }
    }
}

.scheduler-screen {
    margin: $margin-h-width $margin-h-width;

    .p-card-content {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    }
}

.user-selector {
    margin-bottom: 12px;    
    overflow: hidden;
    padding: 10px;

    @media screen and (max-width: $md) {
        max-height: none;
    }

    @media screen and (max-height: 400px) {
        max-height: none;
    }

    .profile-photo-miniature {
        border-radius: 50%;
        width: 80%;
    }
    
    .user-selector-buttons {
        display: flex;
        flex-direction: column;
        padding-top: 10px;

        .p-button {
            margin-bottom: 10px;
            padding: 0;

            &.p-disabled * {
                pointer-events: auto;
            }
        }

        .p-button:not(.p-disabled):not(.p-highlight):hover {
            background-color: $secondary-color-gray;
            border-color: $secondary-color-gray;
            color: $text-color;
        }

        .p-button:not(.p-disabled) {
            .user-item .user-item-name {
                &:hover {
                    background-color: $primary-orange;
                    color: $primary-color-text;
                }
            }
        }
    }

    .user-item {
        display: flex;
        vertical-align: middle;
        align-items: center;
        width: 100%;
        padding: 0;
        height: 40px;

        .user-profile-trigger {
            flex-grow: 0;
            text-align: left;
            vertical-align: middle;
            cursor: pointer !important; //this is to override a prime react disbaled !important class
            background-color: $functional-white;
            color: $text-color;
            height: 100%;
            padding: 10px;

            &:hover {
                background-color: $primary-orange;
                color: $primary-color-text;
            }
        }

        .provider-item-name {
            text-align: left;
            flex-grow: 1;
            padding-left: 5px;
            display: flex;
            align-items: center;
            height: 100%;
        }
     
        .user-item-icons {
            display: flex;
            margin-right: 10px;
        }

        span {
            vertical-align: middle;
        }
    }

    .util {
        margin-left: 10px;
        vertical-align: middle;
    }

    input:focus {
        box-shadow: none;
    }
}

.util-red {
    color: $attention-text-color;
}

.util-orange {
    color: $primary-orange;
}

.util-green {
    color: green;
}

.users-selector input {
    width: 100%;
}

.field-label {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    .attention {
        font-size: 0.9rem;
        color: $attention-text-color;
    }

    .warning {
        font-size: 0.9rem;
        color: $warning-text-color;
    }
}

.p-overlaypanel:after {    
    content: none !important;
}

.p-overlaypanel:before {    
    content: none !important;
}

.user-detail-overlay {
    background-color: $secondary-color-lighter-gray;
    border: 2px solid !important;
    border-color: $functional-dark-grey !important;
    width: 400px;
    
    .p-overlaypanel-content {
        white-space: normal;
    }

    .profile-header {
        display: flex;
        align-items: center;

        .profile-header-right {
            margin-left: 20px;
            line-height: 1.5rem;
        }
    }

    .profile-details {
        line-height: 1.8rem;
        vertical-align: middle;

        span {
            vertical-align: middle;
        }

        .util {
            margin-left: 0;
            margin-right: 10px;
        }

        .loader {
            margin-top: 10vh;
            margin-bottom: 10vh;
        }

        .p-inline-message {
            margin: 5px;
            width: 90%;
            font-size: 14px;
        }            
    }
    .p-divider:before {
        border-top: 1px solid rgba($functional-black, 0.2);
    }
}

.scheduler-confirmation-screen {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    text-align: left;

    @media screen and (max-width: $md) {
        flex-direction: column;
    }

    .no-email-message {
        font-size: 15px;
        color: $error-text-color;
        max-width: 626px;
    }
    
    .no-email-warning {
        color: $error-text-color;
    }

    .reassign-check-box {
        padding-bottom: 2%;
    }
    
    .appointment-type-drop {
        width: 180px;
    }
    

    .profile-availability-info {
        font-size: 14px;
    }
    
    .section {
        padding: 20px;
        display: flex;
        flex-direction: column;

        &.left {
            flex-basis: 400px;
            border-right: 1px solid $functional-light-grey;

            @media screen and (max-width: $md) {
                border-right: none;
                flex-basis: auto;
            }
        }
        
        &.conclusion-width {
            width: 50%;
        }

        &.right {
            flex-grow: 1;
            margin-left: 20px;
            @media screen and (max-width: $md) {
                border-top: 1px solid $functional-light-grey;
                margin-left: 0;
            }
        }

        .text-title {
            line-height: 2rem;
            font-size: 1.2rem;
        }
        .p-field label {
            display: block;
        }

        .notes {
            textarea {
                width: 100%;
            }
        }

        .scheduler-confirmation-buttons {
            text-align: right;
            

            button:not(:last-child) {
                margin-right: 10px;
            }
        }
    }

    .userSelectorHolder {
        width: 70%;
        margin-right: 15px;
        .p-autocomplete,
        input {
            width: 100%;
        }
    }  
    

    .episode-user-selector {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .p-field.attention {
        input {
            border-color: $attention-text-color;
        }
    }
    .p-field.warning {
        input {
            border-color: $warning-text-color;
        }
    }
}

.scheduler-time-selector {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    @media screen and (max-width: $md) {
        flex-direction: column;
    }

    .calendar-duration-pref-container {
        margin: 0px $margin-h-width $margin-h-width;
        vertical-align: top;
        align-items: stretch;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: $md) {
            width: auto;
            margin: inherit;
        }

        .duration-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 20px;
    
            @media screen and (max-width: $md) {
                flex-direction: row;
                flex-wrap: wrap;
            }
    
            .p-radiobutton {
                margin-right: 10px;
            }
        }

        .p-datepicker-buttonbar {
            padding: 4px;
            button:nth-child(1) {
                flex-grow: 1;
            }
            button:nth-child(2) {
                display: none;
            }
        }
    }

    .availability-slots {
        flex-grow: 1;
        margin-left: $margin-h-width;
        margin-right: $margin-h-width;

        @media screen and (max-width: $md) {
            border-top: 1px solid $functional-light-grey;
        }

        h3 {
            margin-bottom: 8px;
        }

        .invalid-age {
            display: block;
            text-align: left;
        }

        .scheduler-timeslot-header {
            
                display: flex;
                flex-direction: row;
                justify-content: space-between;
        
            
            @media screen and (max-width: $xl) {
                display: block;
                flex-direction: row;
                justify-content: space-between;
            }

            .left-container {
                text-align: left;
            }
            .right-container {
                display: flex;
                flex-direction: column-reverse;
                text-align: right;
            }
        }

        .subtitle-block {
            color: rgba($primary-orange, 0.9);
            font-size: 1rem;
            font-weight: bold;
        } 
        .more-less-button {
            color: rgba($primary-orange, 0.9);
        }
        .more-less-button:hover {           
            color: white;            
        }

        .refine-slot-select {
            margin-bottom: 24px;
            display: flex;
            flex-wrap: wrap;

            .p-multiselect-label-container {
                max-width: calc(100% - 40px);
            }

            .p-multiselect-label {
                display: flex;
                flex-wrap: wrap;

                .p-multiselect-token {
                    margin-bottom: 0.2rem;
                }
            }

            &.invalid-age {
                margin-bottom: 0;
            }
        }

        .timeslot-selector {
            margin-top: 10px;
            @media screen and (max-width: $md) {
                max-height: none;
            }

            @media screen and (max-height: 400px) {
                max-height: none;
            }

            max-height: calc(100vh - 350px);
            overflow: auto;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;
            scrollbar-width: thin;

            .p-button {
                margin: 6px;
                width: 90px;
                height: 50px;
                padding: 0;
            }

            .slot-text {
                text-align: center;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 450;
                &.util-red {
                    border-left: 6px solid $attention-text-color;
                }
            }
            .bold-slot {
                font-weight: bold;
            }
        }
    }
}
