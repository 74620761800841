@import 'concert-ui-library/dist/styles/theme/styles/layout';
@import 'concert-ui-library/dist/styles/theme/styles/colors';

.message-center-container {
    margin: auto;
    padding: 0.5rem;
    padding-top: 0rem;
    margin-top: 0rem;

    .ql-snow .ql-editor a {
        text-wrap: wrap;
        line-break: anywhere;
    }

    h2 {
        margin-bottom: 2rem;
    }
    .p-card-title {
        text-align: center;
        padding: 0;
    }
    .p-card-body {
        padding: 0.1rem 0.75rem;
    }

    .p-card-content {
        padding: 0;
    }

    .p-breadcrumb {
        ol {
            > li {
                &.unclickable a {
                    cursor: default;
                }
                &.p-menuitem-separator:last-child {
                    display: none;
                }
                &.active {
                    font-weight: bold;
                }
            }

            li > a {
                font-size: 1.2rem;
            }
        }
    }
    .conversation-header-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: .5rem;
        margin-bottom: .5rem;
    }
}

.message-center-container {
    .message-card:hover {
        background-color: $functional-light-grey;
    }
    .unopened-messages-conversation {
        div {
            font-weight: bold;
        }
        .p-card-title {
            font-weight: bold;
        }
    }
    .p-editor-content .ql-editor {
        color: $text-color;
        font-size: 0.95rem;
    }
    .unopened-messages-conversation:hover {
        .p-card-body {
            background-color: $functional-light-grey;
        }
    }
    .message-card {
        margin-bottom: 0.5rem;
        padding-left: 1rem;
        text-align: left;

        .p-card-body {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding-left: 0.2rem;
            padding-right: 0.2rem;
            padding-top: 0rem;
            padding-bottom: 0rem;
        }
        .p-card-title {
            flex: 50%;
            font-size: 1.2rem;
            text-align: left;
            padding-top: 1rem;
            padding-bottom: 1rem;
            padding-right: 1.7rem;
            font-weight: 500;
            margin-bottom: 0rem;
        }
        .p-card-content {
            flex: 50%;
            font-size: 1rem;
            text-align: right;
            padding-right: 1rem;
            margin-top: auto;
            margin-bottom: auto;
        }

        &.patient-read-all-messages {
            .p-card-content {
                > p {
                    display: inline-flex;
                }
                &:before {
                    font-family: 'PrimeIcons';
                    content: '\e90a';
                    align-self: center;
                    padding-right: 0.5rem;
                }
            }
        }

        @media screen and (max-width: $sm) {
            .p-card-body {
                flex-direction: column;
            }
            .p-card-title {
                text-align: left;
                padding-top: 0.5rem;
                padding-bottom: 0.7rem;
                padding-right: 1.8rem;
            }
            .p-card-content {
                text-align: left;
                p {
                    margin-top: 0rem;
                    margin-bottom: 1rem;
                }
            }
        }
    }
    .message-card:hover {
        background-color: $functional-light-grey;
    }
    .unopened-messages-conversation {
        div {
            font-weight: bold;
        }
        .p-card-title {
            font-weight: bold;
        }
    }
    .unopened-messages-conversation:hover {
        .p-card-body {
            background-color: $functional-light-grey;
        }
    }
    .link-button-container {
        margin-top: 1rem;
    }
    .conversation-btn {
        padding: 0.2rem 0rem 0.2rem 0rem;
        display: block;
        float: right;
        .p-button {
            border-radius: 2rem;
            padding: 0.6rem;
            font-weight: bold;
        }
        @media screen and (max-width: $sm) {
            .p-button {
                .p-button-label {
                    display: none;
                }
                border-radius: 2rem;
                padding: 0.6rem 0px 0.6rem 0.6rem;
                font-weight: bold;
            }
        }
    }
    
    .p-datatable-table {
        border-spacing: 2px;
    }
    .message-sender-container {
        .visible-form {
            margin-top: 1.5rem;
            border-bottom: 1px solid rgba(0, 0, 0, 0.203);
            padding-bottom: 0.2rem;
            margin-bottom: 2.3rem;
        }
        .p-editor-container {
            width: 100%;
            min-height: 7rem;
            margin-bottom: 2.5rem;
            .p-editor-toolbar {
                text-align: left;
            }
            .ql-link {
                background-color: none;
            }
        }
        .p-editor-content {
            resize: vertical;
            overflow: auto;
        }
        .p-invalid > .p-editor-content {
            border-color: $warning-color;
        }

        .ql-editor {
            font-size: 1rem;
            &::before {
                content: attr(data-placeholder);
                position: absolute;
                font-family: Open Sans, sans-serif;
                font-size: 1rem;
                color: #a6a6a6;
                font-style: normal;
                pointer-events: none;
                opacity: 1;
                visibility: hidden;
            }

            &.ql-blank::before {
                visibility: visible;
            }
        }

        .message-send-buton {
            margin-bottom: 1.5rem;
            button {
                margin-left: 10px;
            }
        }
        .p-inputgroup {
            padding: 0.5rem;
        }
    }
}
