@import 'concert-ui-library/dist/styles/theme/styles/colors';

.small-inline {
    .loader {
        &.inline {
            margin-left: 0;

            .p-progress-spinner {
                height: 1.5rem;
                width: 1.5rem;
            }
        }
    }
}

.other-settings-container {
    display: flex;
    padding: 0px 20px;
    flex-direction: column;
    text-align: left;

    .caseload-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-right: 10%;

        .caseload-info {
            display: flex;
            flex-direction: column;
        }
        .caseload-label {
            font-weight: bold;
        }
        .botton-accepting-new-patientes {
            text-align: right;
        }
    }
    .availability-label {
        margin-bottom: 0.5rem;
    }
    padding: 0px 20px;
    .p-button {
        margin-bottom: 20px;
    }
    .practice-selector {
        width: 50%;
        margin-bottom: 20px;
    }

    .practice-multi-selector {
        width: 50%;
        margin-bottom: 20px;
     }
    
    .p-field {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;

        .loader.inline {
            vertical-align: bottom;
        }

        .p-inputnumber {
            width: 90%;
        }

        .p-multiselect-label {
            display: flex;
            flex-wrap: wrap;
        }
        .p-togglebutton {
            width: 100px;
        }
        .p-multiselect {
            width: 90%;
            
        }

        .p-multiselect.practice-multi-selector {
            width: 80%;
            margin-right: 3%
        }

        .p-tree {
            width: 90%;
        }

        .p-multiselect-token {
            margin-bottom: 0.5rem;
        }

        .disabled {
            cursor: auto;
        }
    }

    .p-tree {
        padding: 0.75rem;

        .p-tree-loading-overlay {
            background-color: rgb($primary-light-orange, 0.2);
        }

        .p-tree-loading-icon {
            color: $functional-dark-grey;
        }
    }
}
