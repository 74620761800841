@import 'concert-ui-library/dist/styles/theme/styles/layout';
@import 'concert-ui-library/dist/styles/theme/styles/colors';

.message-center-container {
    .p-breadcrumb {
        ol {
            > li {
                &.unclickable a {
                    cursor: default;
                }
                &.active {
                    font-weight: bold;
                }
            }

            li > a {
                font-size: 1.2rem;
            }
        }
    }
}