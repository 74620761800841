@import 'concert-ui-library/dist/styles/theme/styles/concerthealth-theme';
@import 'concert-ui-library/dist/styles/theme/styles/colors';
@import 'concert-ui-library/dist/styles/theme/styles/layout';
@import 'concert-ui-library/dist/styles/components/styles';

.App {
    text-align: center;
    display: block;
}

html {
    min-height: 100%;
}

body {
    width: 100vw;
    margin: 0;
    background: linear-gradient(180deg, $functional-light-grey 0%, $functional-white 100%);
}

.app-container {
    max-width: $xxl;
    text-align: center;
    margin: auto;
}

.sub-header {
    background-color: $functional-white;

    .p-panel-content {
        border: 0;
    }

    .p-panel .p-panel-header {
        border: unset;
    }
}

.required {
    &::after {
        color: $error-text-color;
        font-weight: bold;
        content: '*';
    }
}

.error {
    margin-top: 10px;
    color: $error-text-color;
    font-size: 0.95rem;
}

.p-datatable {
    & &-thead > {
        tr > {
            th {
                border-top: none;
            }
        }
    }

    & &-tbody {
        td {
            vertical-align: top;
            .p-button {
                padding: 0;
            }

            .p-progress-spinner {
                width: 1em;
                height: 1em;
            }

            .loading {
                &.invisible {
                    visibility: hidden;
                }
                &.visible {
                    visibility: visible;
                }
            }
        }
        
        @media screen and (max-width: $lg) {
            & &-thead > tr > th {
                &:first-of-type:nth-child(1),
                &:last-of-type:nth-child(2) {
                    display: table-cell;
                }
            }

            & &-tbody {
                > tr {
                    display: table-row;

                    &:not(:last-child) {
                        border-bottom: 1px solid $functional-medium-grey;
                    }

                    > td {
                        display: table-cell;
                        text-align: left;

                        > .p-column-title {
                            display: nonet;
                        }

                        button {
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
}
