.episode-selector {
    width: 100%;
    input {
        width: 90%;
    }
    .episode-subtext {
        font-size: 0.8rem;

        .period-seperator {
            font-size: 0.2rem;
            line-height: 1rem;
            vertical-align: middle;
            margin-left: 5px;
            margin-right: 5px;
            font-weight: 200;
        }
    }

}